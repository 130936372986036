import React, { useContext } from "react";
import { Layout, Icon } from "antd";
import { Fragments, Elements } from "components";
import { permissions } from "common/constants";
import { UIContext } from "components/providers";
import { useFeatureFlag } from "hooks";
import css from "./routes.module.css";
import sitemap from "./sitemap";
import { BackToTop } from "components/common/BackToTop";

const {
  Permissions: { Permission },
  LocationDropdown,
  RestaurantDropdown,
} = Fragments;
const { Header: AntHeader } = Layout;
const { T } = Elements;
const {
  routes: { LOCATIONS_LIST },
} = permissions;

const setLocationKey = (key) => (key === "all" ? "" : key);
const setLocationValue = (key) => {
  if (key === "") {
    return "all";
  }
  return key;
};

const ZendeskArticlesButton = ({ currentUrl }) => {
  let zendeskArticle;
  Object.keys(sitemap).forEach((map) => {
    if (sitemap[map].path === currentUrl) {
      zendeskArticle = sitemap[map].zendeskArticles;
    }
  });
  if (zendeskArticle) {
    return (
      <Icon
        className={styles["info-icon"]}
        type="info-circle"
        alt="Help Button"
        onClick={() => window.open(zendeskArticle)}
      />
    );
  }
  return null;
};

const Header = ({
  onActiveLocationChange,
  activeLocationID,
  locations,
  pathname,
  currentUrl,
}) => {
  const isEnabled = useFeatureFlag("admin_redesign");
  const { setNewUI } = useContext(UIContext);

  return (
    <AntHeader className={css.header}>
      <Permission
        requiredPermissions={LOCATIONS_LIST}
        isNav
        yes={() => (
          <LocationDropdown
            label={
              <T className={css.locationLabel} inline label>
                Location Filter:
              </T>
            }
            className={css.navHeader}
            locations={locations}
            route={pathname}
            value={setLocationValue(activeLocationID)}
            onLocationChange={(key) => {
              onActiveLocationChange(setLocationKey(key));
            }}
          />
        )}
      />
      <RestaurantDropdown className={css.navHeader} />

      <div className={css.zendeskDisplay}>
        <BackToTop />
        <ZendeskArticlesButton currentUrl={currentUrl} />
      </div>
      <button
        type="button"
        onClick={() => setNewUI(true)}
        className={css.goForward}
      >
        Go to new admin
      </button>
    </AntHeader>
  );
};

export default Header;
